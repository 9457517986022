<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
    
      <v-card  elevation="8" class="mt-5">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">Transferir Protocolo</h3>
          </h3></v-card-title
        >
        <div class="card-body">
          <div class="col-md-12">
            <div class="form">
              <div class="row form-group">
                <div class="col-md-4">
                  <label class="col-md-12">Filial:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_filials"
                    placeholder="Selecione o Tipo..."
                    v-model="form.filial_tf"
                    @input="input"
                  />
                </div>

                <div class="col-md-4">
                  <label class="col-md-12">Setor:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_setor"
                    placeholder="Selecione o Setor..."
                    v-model="form.setor_id"
                    @input="input2"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Usuario:*</label>
                  <treeselect
                    :required="false"
                    :multiple="false"
                    :options="lista_usuarios"
                    placeholder="Selecione o Setor..."
                    v-model="form.usuario_id"
                  />
                </div>
                <!-- <div class="col-md-3">
                      <label class="col-md-12">Data Vencimento:</label>
                      <input
                        required=""
                        type="date"
                        class="form-control"
                        v-model="form.data_vencimento"
                      />
                    </div> -->
                <div class="col-md-8">
                  <label for="porcentagem">Descrição:</label>
                  <textarea
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a Descrição"
                  ></textarea>
                </div>
              </div>
              <div class="row justify-content-end">
          <div class="col-md-12 text-center">
            <v-btn
              color="primary"
              elevation="2"
              :loading="verif"
              @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
              class="btn btn-primary"
              :disabled="verif"
              >{{ lista_campos == "" ? "cria" : "edita" }}</v-btn
            >
          </div>
        </div>
  
            </div>
          </div>
        </div>
        
      </v-card>
    </div>
  </div>
</template>
  
  <script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import filialService from "@/core/services/filial.service";

export default {
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      verif: false,

      form: {
        id: null,
    data_vencimento: null,
    usuario_id:null,
        descricao: null,
        protocolos_id: null,
        setor_id: null,
        filial_tf: null,
      },
      variableWithMask: "",

      value: "",
      loading: false,
      cpf_cnpj1: null,
      saldo: null,
      filial:filialService.getFilialId()
    };
  },

  computed: {
    lista_usuarios() {
      return this.$store.state.setor.lista_usuarios.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    mensagem_alert() {
      return this.$store.state.tf.mensagem_alert;
    },
    lista_tipo_protocolo() {
      return this.$store.state.tipoProtocolo.lista_tipo_protocolo.map(
        (cat) => ({
          id: cat.id,
          label: cat.nome,
        })
      );
    },
    lista_setor() {
      return this.$store.state.setor.lista_setor.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_filials() {
      let filiais = this.$store.state.configEmpresa.lista_filials.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
if(this.filial == 1){
      return filiais
    }else{
      return [filiais[0]]

    }
    },
  },
  created() {
    this.preenxerCampor();
  },
  watch: {},
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação  ` + tipo + ` uma conta para o cliente no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
     this.form.protocolos_id = this.$route.params.protocolo_id
      this.verif = true;
      await this.$store.dispatch("tf/create_tfprotocolo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "dashboard",
      });
    },
    // onInput(value) {
    //   let usuarios = Object.values(
    //     this.$store.state.configUsuarios.lista_usuarios
    //   );
    //   usuarios = usuarios.filter((use) => use.id === value);
    //   console.log(usuarios[0]);
    //   this.cpf_cnpj1 = usuarios[0].cpf;
    // },
    async update() {
      this.verif = true;
      await this.$store.dispatch("tf/update_tfprotocolo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "dashboard",
      });
    },
    input(item) {
      this.$store.dispatch("setor/listar_setor_filial", item);
    },
    input2(item) {
      this.$store.dispatch("setor/listar_usuarios_setor", item);
    },
    async preenxerCampor() {
      await this.$store.dispatch("tipoProtocolo/listar_tipo_protocolo");
      await this.$store.dispatch("configEmpresa/listar_filial");

      this.$store.dispatch("setor/listar_setor");
      this.$store.dispatch("configUsuarios/listar_usuarios");


      console.log(this.$store.state.sistema.lista_filiais_autorizada);
      if (this.lista_campos != "") {
        var protocolo = this.lista_campos.protocolo;

        this.form = {
          id: null,
         data_vencimento: null,
        descricao: null,
        protocolo_id: null,
        setor_id: null,
        filial_tf: null,

        };
      }
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>